import { AnalyticsGuard } from './../guards/analytics.guard'
import { BalanceGuard } from './../guards/balance.guard'
import { BannerGuard } from './../guards/banner.guard'
import { BrandGuard } from './../guards/brand.guard'
import { CampaignGuard } from './../guards/campaign.guard'
import { CategoryGuard } from './../guards/category.guard'
import { ConfigGuard } from './../guards/config.guard'
import { CustomerGuard } from './../guards/customer.guard'
import { DepartmentGuard } from './../guards/department.guard'
import { GiftcardGuard } from './../guards/giftcard.guard'
import { ImportFormGuard } from './../guards/import-form.guard'
import { MarketingGuard } from './../guards/marketing.guard'
import { OrderStatsMessageGuard } from './../guards/order-stats-message.guard'
import { OrdersGuard } from './../guards/orders.guard'
import { PaymentSystemGuard } from './../guards/payment-system.guard'
import { ProductGuard } from './../guards/product.guard'
import { ProfileGuard } from './../guards/profile.guard'
import { PromotionGuard } from './../guards/promotion.guard'
import { RefundGuard } from './../guards/refund.guard'
import { Routes } from '@angular/router'
import { SellerBillGuard } from './../guards/seller-bill.guard'
import { ShopTypesGuard } from './../guards/shop-types.guard'
import { ShopsGuard } from './../guards/shops.guard'
import { SinglePageGuard } from './../guards/single-page.guard'
import { SmsBodyGuard } from './../guards/sms-body.guard'
import { SubCategoryGuard } from './../guards/sub-category.guard'
import { TransactionGuard } from './../guards/transaction.guard'
import { UserGuard } from './../guards/user.guard'
import { VarientGuard } from './../guards/varient.guard'
import { VarientOptionGuard } from './../guards/varient-option.guard'
import { VendorGuard } from './../guards/vendor.guard'

export const content: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: 'dashboard',
		loadChildren: () =>
			import('../../@module/dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
	},
	{
		path: 'orders',
		loadChildren: () =>
			import('../../@module/order/order.module').then((m) => m.OrderModule),
		canActivate: [OrdersGuard, ProfileGuard],
	},
	{
		path: 'shops',
		loadChildren: () =>
			import('../../@module/shops/shops.module').then((m) => m.ShopsModule),
		canActivate: [ShopsGuard, ProfileGuard],
	},
	{
		path: 'shop-type',
		loadChildren: () =>
			import('../../@module/shop-type/shop-type.module').then(
				(m) => m.ShopTypeModule
			),
		canActivate: [ShopTypesGuard, ProfileGuard],
	},
	{
		path: 'order-message',
		loadChildren: () =>
			import(
				'../../@module/order-status-message/order-status-message.module'
			).then((m) => m.OrderStatusMessageModule),
		canActivate: [OrderStatsMessageGuard, ProfileGuard],
	},
	{
		path: 'brands',
		loadChildren: () =>
			import('../../@module/brands/brands.module').then((m) => m.BrandsModule),
		canActivate: [BrandGuard, ProfileGuard],
	},
	{
		path: 'categories',
		loadChildren: () =>
			import('../../@module/categories/categories.module').then(
				(m) => m.CategoriesModule
			),
		canActivate: [CategoryGuard, ProfileGuard],
	},
	{
		path: 'sub-category',
		loadChildren: () =>
			import('../../@module/sub-category/sub-category.module').then(
				(m) => m.SubCategoryModule
			),
		canActivate: [ProfileGuard],
	},
	{
		path: 'deal-of-the-day',
		loadChildren: () =>
			import('../../@module/deal-of-the-day/deal-of-the-day.module').then(
				(m) => m.DealOfTheDayModule
			),
		canActivate: [SubCategoryGuard, ProfileGuard],
	},
	{
		path: 'products',
		loadChildren: () =>
			import('../../@module/products/products.module').then(
				(m) => m.ProductsModule
			),
		canActivate: [ProductGuard, ProfileGuard],
	},
	{
		path: 'banner',
		loadChildren: () =>
			import('../../@module/banner/banner.module').then((m) => m.BannerModule),
		canActivate: [BannerGuard, ProfileGuard],
	},
	{
		path: 'analytics',
		loadChildren: () =>
			import('../../@module/analytics/analytics.module').then(
				(m) => m.AnalyticsModule
			),
		canActivate: [AnalyticsGuard, ProfileGuard],
	},
	{
		path: 'balance',
		loadChildren: () =>
			import('../../@module/balance/balance.module').then(
				(m) => m.BalanceModule
			),
		canActivate: [BalanceGuard, ProfileGuard],
	},
	{
		path: 'campaign',
		loadChildren: () =>
			import('../../@module/campaign/campaign.module').then(
				(m) => m.CampaignModule
			),
		canActivate: [CampaignGuard, ProfileGuard],
	},
	{
		path: 'customer',
		loadChildren: () =>
			import('../../@module/customer/customer.module').then(
				(m) => m.CustomerModule
			),
		canActivate: [CustomerGuard, ProfileGuard],
	},
	// {
	// 	path: 'footer',
	// 	loadChildren: () =>
	// 		import('../../@module/footer/footer.module').then((m) => m.FooterModule),
	// 	canActivate: [ProfileGuard],
	// },
	{
		path: 'gift-card',
		loadChildren: () =>
			import('../../@module/gift-card/gift-card.module').then(
				(m) => m.GiftCardModule
			),
		canActivate: [GiftcardGuard, ProfileGuard],
	},
	// {
	// 	path: 'grocery-order',
	// 	loadChildren: () =>
	// 		import('../../@module/grocery-order/grocery-order.module').then(
	// 			(m) => m.GroceryOrderModule
	// 		),
	// 	canActivate: [ProfileGuard],
	// },
	// {
	// 	path: 'grocery-shop',
	// 	loadChildren: () =>
	// 		import('../../@module/grocery-shop/grocery-shop.module').then(
	// 			(m) => m.GroceryShopModule
	// 		),
	// 	canActivate: [ProfileGuard],
	// },
	{
		path: 'marketing',
		loadChildren: () =>
			import('../../@module/marketing/marketing.module').then(
				(m) => m.MarketingModule
			),
		canActivate: [MarketingGuard, ProfileGuard],
	},
	{
		path: 'page-create',
		loadChildren: () =>
			import('../../@module/page-create/page-create.module').then(
				(m) => m.PageCreateModule
			),
		canActivate: [SinglePageGuard, ProfileGuard],
	},
	{
		path: 'payment-system',
		loadChildren: () =>
			import('../../@module/payment-system/payment-system.module').then(
				(m) => m.PaymentSystemModule
			),
		canActivate: [PaymentSystemGuard, ProfileGuard],
	},
	{
		path: 'refund',
		loadChildren: () =>
			import('../../@module/refund/refund.module').then((m) => m.RefundModule),
		canActivate: [RefundGuard, ProfileGuard],
	},
	{
		path: 'seller-bill',
		loadChildren: () =>
			import('../../@module/seller-bill/seller-bill.module').then(
				(m) => m.SellerBillModule
			),
		canActivate: [SellerBillGuard, ProfileGuard],
	},
	{
		path: 'settings',
		loadChildren: () =>
			import('../../@module/settings/settings.module').then(
				(m) => m.SettingsModule
			),
		canActivate: [ProfileGuard],
	},
	// {
	// 	path: 'shipping-method',
	// 	loadChildren: () =>
	// 		import('../../@module/shipping-method/shipping-method.module').then(
	// 			(m) => m.ShippingMethodModule
	// 		),
	// 	canActivate: [ProfileGuard],
	// },
	{
		path: 'user',
		loadChildren: () =>
			import('../../@module/user/user.module').then((m) => m.UserModule),
		canActivate: [UserGuard, ProfileGuard],
	},
	// {
	// 	path: 'user-permission',
	// 	loadChildren: () =>
	// 		import('../../@module/user-permission/user-permission.module').then(
	// 			(m) => m.UserPermissionModule
	// 		),
	// 	canActivate: [ProfileGuard],
	// },
	{
		path: 'departments',
		loadChildren: () =>
			import('../../@module/department/department.module').then(
				(m) => m.DepartmentModule
			),
		canActivate: [DepartmentGuard, ProfileGuard],
	},
	{
		path: 'vendors',
		loadChildren: () =>
			import('../../@module/vendor/vendor.module').then((m) => m.VendorModule),
		canActivate: [VendorGuard, ProfileGuard],
	},
	// {
	// 	path: 'discount',
	// 	loadChildren: () =>
	// 		import('../../@module/discount/discount.module').then(
	// 			(m) => m.DiscountModule
	// 		),
	// 	canActivate: [ProfileGuard],
	// },
	{
		path: 'countries',
		loadChildren: () =>
			import('../../@module/country/country.module').then(
				(m) => m.CountryModule
			),
		canActivate: [ImportFormGuard, ProfileGuard],
	},
	{
		path: 'profile',
		loadChildren: () =>
			import('../../@module/profile/profile.module').then(
				(m) => m.ProfileModule
			),
	},
	{
		path: 'promotion',
		loadChildren: () =>
			import('../../@module/promotion/promotion.module').then(
				(m) => m.PromotionModule
			),
		canActivate: [PromotionGuard],
	},
	{
		path: 'configs',
		loadChildren: () =>
			import('../../@module/configs/configs.module').then(
				(m) => m.ConfigsModule
			),
		canActivate: [ConfigGuard],
	},
	{
		path: 'varient',
		loadChildren: () =>
			import('../../@module/variant/variant.module').then(
				(m) => m.VariantModule
			),
		canActivate: [VarientGuard],
	},
	{
		path: 'varient-option',
		loadChildren: () =>
			import('../../@module/varient-option/varient-option.module').then(
				(m) => m.VarientOptionModule
			),
		canActivate: [VarientOptionGuard],
	},
	{
		path: 'sms-body',
		loadChildren: () =>
			import('../../@module/smsBody/sms-body.module').then(
				(m) => m.SmsBodyModule
			),
		canActivate: [SmsBodyGuard],
	},
	{
		path: 'transactions',
		loadChildren: () =>
			import('../../@module/transaction/transaction.module').then(
				(m) => m.TransactionModule
			),
		canActivate: [TransactionGuard],
	},
	{
		path: 'badges',
		loadChildren: () =>
			import('../../@module/badges/badges.module').then((m) => m.BadgesModule),
		canActivate: [TransactionGuard],
	},
]
