import { CurrentUserState } from './../../@state/current-user/current-user.state';
import { getCurrentUserAndTokenSelector } from './../../@state/current-user/current-user.selector';
import { logout } from './../../@state/current-user/current-user.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getCurrentUserSelector } from 'src/app/@state/current-user/current-user.selector';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from 'src/app/@state/app.state';
import { Store } from '@ngrx/store';
import { CurrentUser } from '../model/currentUser.model';
import { ChangePassword } from '../model/changePassword.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly EndPoint = environment.API_ENDPOINT + 'auth/';

	constructor(
		private readonly http: HttpClient,
		private readonly store: Store<AppState>
	) {}

	login(payload: any): Observable<any> {
		return this.http.post(`${this.EndPoint}login/admin`, payload);
	}

	logout() {
		this.store.dispatch(logout());
	}

	isLoggedIn() {
		let flag: boolean;
		this.store
			.select(getCurrentUserAndTokenSelector)
			.pipe(untilDestroyed(this))
			.subscribe((data: CurrentUserState) => {
				if (data) {
					// const expTime = new Date(data.exp * 1000);
					// const currentTime = new Date();

					// if (expTime >= currentTime) {
					// 	flag = true;
					// } else {
					// 	flag = false;
					// }
					if (data.currentUser && data.jwtToken) {
						flag = true;
					} else {
						flag = false;
					}
				} else {
					flag = false;
				}
			});
		return flag;
	}

	changePassword(payload: ChangePassword): Observable<any> {
		return this.http.post(`${this.EndPoint}changePassword/admin`, payload);
	}

	generateNewJwt(payload) {
		return this.http.post(`${this.EndPoint}utils/refreshToken`, payload);
	}

	forgetPassword(payload: { phoneNumber: string }): Observable<any> {
		return this.http.post(`${this.EndPoint}forgetPassword/admin`, payload);
	}

	verifyOtpAndResetPassword(payload: {
		phoneNumber: string;
		password: string;
		otp: string;
	}): Observable<any> {
		return this.http.post(`${this.EndPoint}resetPassword/admin`, payload);
	}
}
