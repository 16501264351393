import { Permission } from './../model/permission.model';
import { getCurrentUserAndTokenSelector } from './../../@state/current-user/current-user.selector';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from 'src/app/@state/app.state';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	protected readonly EndPoint = environment.API_ENDPOINT + 'permissions/';
	permission: Permission[] = [];
	constructor(
		protected readonly http: HttpClient,
		private store: Store<AppState>
	) {
		this.store.select(getCurrentUserAndTokenSelector).subscribe((data) => {
			this.permission = data.permission;
		});
	}

	getAllPermission(userId: string): Observable<any> {
		return this.http.get(this.EndPoint + userId);
	}

	addPermission(payload: any): Observable<any> {
		return this.http.post(this.EndPoint + 'addPermission', payload);
	}

	removePermission(payload: any): Observable<any> {
		return this.http.post(this.EndPoint + 'removePermission', payload);
	}

	isAnalytics(): boolean {
		const analytics = this.permission.find((p) => {
			if (p.name == 'Analytics' && p.isPermissionGranted) {
				return p;
			}
		});
		if (analytics) {
			return true;
		} else {
			return false;
		}
	}

	isOrder(): boolean {
		const order = this.permission.find((p) => {
			if (p.name == 'Orders' && p.isPermissionGranted) {
				return p;
			}
		});
		if (order) {
			return true;
		} else {
			return false;
		}
	}

	isShops(): boolean {
		const shop = this.permission.find((p) => {
			if (p.name == 'Shops' && p.isPermissionGranted) {
				return p;
			}
		});
		if (shop) {
			return true;
		} else {
			return false;
		}
	}

	isShopTypes(): boolean {
		const shopTypes = this.permission.find((p) => {
			if (p.name == 'Shop Types' && p.isPermissionGranted) {
				return p;
			}
		});
		if (shopTypes) {
			return true;
		} else {
			return false;
		}
	}

	isOrderStatusMessage(): boolean {
		const orderStatusMessage = this.permission.find((p) => {
			if (p.name == 'Order Status Message' && p.isPermissionGranted) {
				return p;
			}
		});
		if (orderStatusMessage) {
			return true;
		} else {
			return false;
		}
	}

	isBrand(): boolean {
		const brand = this.permission.find((p) => {
			if (p.name == 'Brands' && p.isPermissionGranted) {
				return p;
			}
		});
		if (brand) {
			return true;
		} else {
			return false;
		}
	}

	isCategory(): boolean {
		const category = this.permission.find((p) => {
			if (p.name == 'Categories' && p.isPermissionGranted) {
				return p;
			}
		});
		if (category) {
			return true;
		} else {
			return false;
		}
	}

	isSubCategory(): boolean {
		const subCategory = this.permission.find((p) => {
			if (p.name == 'Sub Category' && p.isPermissionGranted) {
				return p;
			}
		});
		if (subCategory) {
			return true;
		} else {
			return false;
		}
	}

	isProduct(): boolean {
		const product = this.permission.find((p) => {
			if (p.name == 'Products' && p.isPermissionGranted) {
				return p;
			}
		});
		if (product) {
			return true;
		} else {
			return false;
		}
	}

	isBanner(): boolean {
		const banner = this.permission.find((p) => {
			if (p.name == 'Banners' && p.isPermissionGranted) {
				return p;
			}
		});
		if (banner) {
			return true;
		} else {
			return false;
		}
	}

	isBalance(): boolean {
		const balance = this.permission.find((p) => {
			if (p.name == 'Balance Add & Deduct' && p.isPermissionGranted) {
				return p;
			}
		});
		if (balance) {
			return true;
		} else {
			return false;
		}
	}

	isCampaign(): boolean {
		const campaign = this.permission.find((p) => {
			if (p.name == 'Campaigns' && p.isPermissionGranted) {
				return p;
			}
		});
		if (campaign) {
			return true;
		} else {
			return false;
		}
	}

	isCustomer(): boolean {
		const customer = this.permission.find((p) => {
			if (p.name == 'Customers' && p.isPermissionGranted) {
				return p;
			}
		});
		if (customer) {
			return true;
		} else {
			return false;
		}
	}

	isGiftCard(): boolean {
		const giftCard = this.permission.find((p) => {
			if (p.name == 'GiftCards' && p.isPermissionGranted) {
				return p;
			}
		});
		if (giftCard) {
			return true;
		} else {
			return false;
		}
	}

	isMarketing(): boolean {
		const marketing = this.permission.find((p) => {
			if (p.name == 'Marketings' && p.isPermissionGranted) {
				return p;
			}
		});
		if (marketing) {
			return true;
		} else {
			return false;
		}
	}

	isSinglePage(): boolean {
		const singlePage = this.permission.find((p) => {
			if (p.name == 'Single Page' && p.isPermissionGranted) {
				return p;
			}
		});
		if (singlePage) {
			return true;
		} else {
			return false;
		}
	}

	isPaymentSystem(): boolean {
		const paymentSystem = this.permission.find((p) => {
			if (p.name == 'Payment System' && p.isPermissionGranted) {
				return p;
			}
		});
		if (paymentSystem) {
			return true;
		} else {
			return false;
		}
	}

	isRefund(): boolean {
		const refund = this.permission.find((p) => {
			if (p.name == 'Refund Process' && p.isPermissionGranted) {
				return p;
			}
		});
		if (refund) {
			return true;
		} else {
			return false;
		}
	}

	isSellerBill(): boolean {
		const sellerBill = this.permission.find((p) => {
			if (p.name == 'Seller Bills' && p.isPermissionGranted) {
				return p;
			}
		});
		if (sellerBill) {
			return true;
		} else {
			return false;
		}
	}

	isUser(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'User' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isDepartment(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Departments' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isVendor(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Vendors' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isImportForm(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Imported From' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isPromotion(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Promotion' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isConfig(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Config' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}
	isVarient(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Varient' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isVarientOption(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Varient Option' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isSmsBody(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'SMS Body' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}

	isTransaction(): boolean {
		const data = this.permission.find((p) => {
			if (p.name == 'Transactions' && p.isPermissionGranted) {
				return p;
			}
		});
		if (data) {
			return true;
		} else {
			return false;
		}
	}
}
