<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav sid_drower" position="end" mode="over">
        <div class="_drower_header">
            <button mat-icon-button (click)="drawer.close()">
                <mat-icon>close</mat-icon>
            </button>
            <h1>Transaction history</h1>
        </div>
        <ng-container *ngIf="transactionHistory.length > 0">
            <table class="table _history_table">
                <thead>
                    <tr>
                        <th scope="col">Transaction Id</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Time</th>
                        <th scope="col">To</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of transactionHistory">
                        <td>{{transaction?.transactionId}}</td>
                        <td>৳{{transaction?.amount| number:'1.2-2'}}</td>
                        <td>{{transaction?.transactionTime | date}}</td>
                        <td>{{transaction?.transactionType}}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="transactionHistory.length == 0">
            <div>
                <h2 class="text-center mt-3">
                    No Transaction Found.
                </h2>
            </div>
        </ng-container>
    </mat-drawer>
    <section class="order_details">
        <section class="header container-fluid">
            <button mat-icon-button color="primary" (click)="goBack()">
                <mat-icon>close</mat-icon>
            </button>
            <h1>Order Details</h1>
        </section>

        <section class="invoice_header container-fluid">
            <div class="invoice_header_data container">
                <div class="invNum_Date">
                    <h1>Invoice - {{orderData?.orderCode}}</h1>
                    <p>ORDERED AT {{orderData?.createdAt|date:'medium':'+0600'}}</p>
                </div>
                <div class="invoice_button">
                    <button mat-raised-button (click)="openInvoice()">Invoice</button>
                    <button mat-raised-button color="primary">Sync Payment</button>
                    <button mat-raised-button [matMenuTriggerFor]="searchMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
            </div>
        </section>

        <section class="invoice_details container">
            <div class="row invoice_details_row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-header">
                            Details
                        </div>
                        <div class="card-body row">
                            <div class="col-sm-6">
                                <div class="card">
                                    <div class="card-header sub-card-header no_border_radius">
                                        Customer
                                    </div>
                                    <div class="card-body sub-card-body">
                                        <p>{{orderData?.customer?.firstName|titlecase}}
                                            {{orderData?.customer?.lastName|titlecase}}</p>
                                        <p>{{orderData?.customer?.phoneNumber}}</p>

                                        <!-- <button mat-stroked-button>
                                            <mat-icon>textsms</mat-icon>Message
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="card">
                                    <div class="card-header sub-card-header no_border_radius">
                                        Delivery address
                                    </div>
                                    <div class="card-body sub-card-body">
                                        <address>
                                            {{orderData?.shippingAddress?.firstName|titlecase}}
                                            {{orderData?.shippingAddress?.lastName|titlecase}}
                                            <br>
                                            {{orderData?.billingAddress?.phoneNumber}}<br>
                                            {{orderData?.shippingAddress?.address}}<br>
                                            {{orderData?.shippingAddress?.city}}-{{orderData?.shippingAddress?.zip}}<br>
                                        </address>
                                        <!-- <p>Contact number - {{orderData?.shippingAddress?.phoneNumber}}</p>
                                        <p>{{orderData?.shippingAddress?.address}}</p> -->
                                        <button mat-stroked-button class="edit_btn"
                                            (click)="changeOrderAddress(orderData)">
                                            Edit Address
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header sub-card-header no_border_radius">
                                        Shop
                                    </div>
                                    <div class="card-body sub-card-body">
                                        <div class="shop">
                                            <img *ngIf="orderData?.shop?.logo" [src]="orderData?.shop?.logo">
                                            <div class="info">
                                                <h4><a href="#">{{orderData?.shop?.name}}</a></h4>
                                                <p>{{orderData?.shop?.phoneNumber}}</p>
                                                <p>{{orderData?.shop?.address}}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header sub-card-header no_border_radius">
                                        Order Items
                                    </div>
                                    <div class="card-body sub-card-body">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr *ngFor="let item of orderData?.products; let i = index;">
                                                    <th scope="row">{{i+1}}</th>
                                                    <td><img class="invoice_product_img"
                                                            [src]="item?.product?.productImages.length > 0? item?.product?.productImages[0]?.link: 'assets/images/no_image.jpg'"
                                                            alt=""></td>
                                                    <td>
                                                        <p class="m-0">{{item.name}}</p>
                                                        <span *ngIf="item.variants.length > 0">
                                                            <p class="m-0" *ngFor="let variant of item.variants">
                                                                {{variant}}</p>
                                                        </span>
                                                    </td>
                                                    <td>{{item?.quantity}}</td>
                                                    <td>৳{{item?.mrp|number:'1.2-2'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p class="badge bg-warning text-dark mr-2">
                                            {{orderData?.paymentStatus}}</p>
                                        <button mat-icon-button (click)="changeOrderPaymentStatus(orderData)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <table class="calculation table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td>Sub Total</td>
                                                    <td>৳{{orderData?.totalSubAmount|number:'1.2-2'}}</td>
                                                </tr>
                                                <tr *ngIf="orderData?.totalVat">
                                                    <td>Vat</td>
                                                    <td>৳{{orderData?.totalVat|number:'1.2-2'}}</td>
                                                </tr>
                                                <tr *ngIf="orderData?.deliveryCharge">
                                                    <td>Delivery Charge</td>
                                                    <td>৳{{orderData?.deliveryCharge|number:'1.2-2'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>৳{{orderData?.totalAmount|number:'1.2-2'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Paid</td>
                                                    <td>৳{{orderData?.paidAmount|number:'1.2-2'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Due</td>
                                                    <td>৳{{orderData?.dueAmount|number:'1.2-2'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header sub-card-header no_border_radius">
                                        Notes
                                    </div>
                                    <div class="card-body sub-card-body">
                                        <form [formGroup]="noteForm">
                                            <div class="card-body note" *ngIf="orderData?.note?.length == 0">
                                                <h5>No Notes yet</h5>
                                            </div>
                                            <div class="card-body note" *ngIf="orderData?.note?.length > 0">
                                                <div class="mt-2" *ngFor="let singleNote of orderData.note">
                                                    <p><em>{{singleNote?.createdBy | titlecase}}</em> At
                                                        {{singleNote?.createdAt|date:'medium':'+0600'}}
                                                    </p>
                                                    <blockquote>
                                                        <p>{{singleNote.note}}</p>
                                                    </blockquote>
                                                </div>
                                            </div>
                                            <div class="full_width">
                                                <mat-form-field appearance="fill" style="width: 100%;">
                                                    <textarea cdkTextareaAutosize matInput formControlName="note"
                                                        class="custom_text_area"
                                                        (keyup.control.enter)="sendNote()"></textarea>
                                                    <button matSuffix mat-icon-button color="primary"
                                                        (click)="sendNote()" [disabled]="noteForm.pristine">
                                                        <mat-icon class="cursor">send</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="card col-sm-12">
                            <div class="card-header">
                                Status
                            </div>
                            <div class="card-body">
                                <div class="stpper">
                                    <div class="md-stepper-horizontal orange">
                                        <div class="md-step"
                                            [ngClass]="{'active  done': orderData?.status == 'PENDING'}">
                                            <div (click)="openOrderDialog('PENDING')" class="md-step-circle">
                                                <span>1</span>
                                            </div>
                                            <div class="md-step-title">PENDING</div>
                                            <div class="md-step-bar-left"></div>
                                            <div class="md-step-bar-right"></div>
                                        </div>
                                        <div class="md-step"
                                            [ngClass]="{'active  done': orderData?.status == 'PROCESSING'}">
                                            <div (click)="openOrderDialog('PROCESSING')" class="md-step-circle">
                                                <span>2</span>
                                            </div>
                                            <div class="md-step-title">PROCESSING</div>
                                            <div class="md-step-bar-left"></div>
                                            <div class="md-step-bar-right"></div>
                                        </div>
                                        <div class="md-step"
                                            [ngClass]="{'active  done': orderData?.status == 'SHIPPED'}">
                                            <div (click)="openOrderDialog('SHIPPED')" class="md-step-circle">
                                                <span>3</span>
                                            </div>
                                            <div class="md-step-title">SHIPPED</div>
                                            <div class="md-step-bar-left"></div>
                                            <div class="md-step-bar-right"></div>
                                        </div>
                                        <div class="md-step"
                                            [ngClass]="{'active  done': orderData?.status == 'DELIVERED'}">
                                            <div (click)="openOrderDialog('DELIVERED')" class="md-step-circle">
                                                <span>4</span>
                                            </div>
                                            <div class="md-step-title">DELIVERED</div>
                                            <div class="md-step-bar-left"></div>
                                            <div class="md-step-bar-right"></div>
                                        </div>
                                        <div class="md-step"
                                            [ngClass]="{'active  done': orderData?.status == 'CANCEL'}">
                                            <div (click)="orderData?.status != 'CANCEL' && openOrderDialog('CANCEL')"
                                                class="md-step-circle">
                                                <span>5</span>
                                            </div>
                                            <div class="md-step-title">CANCEL</div>
                                            <div class="md-step-bar-left"></div>
                                            <!-- <div class="md-step-bar-right"></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="status" *ngIf="orderStatus.length > 0 || !isLoading">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Order Status</th>
                                                <th scope="col" style='width: 132px;'>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let status of orderStatus">
                                                <td>{{status.createdAt|date:'medium'}}</td>
                                                <td>
                                                    <p><b>{{status.status}}</b></p>
                                                    <p>{{status.comments}} <span *ngIf="status.createdBy">By
                                                            <strong>{{status.createdBy}}</strong></span></p>
                                                </td>
                                                <td style="text-align: center;">
                                                    <p class="badge" [ngClass]="{
                                'btn-danger': status.paymentStatus === 'UNPAID',
                                'btn-warning': status.paymentStatus === 'PARTIAL PAID', 
                                'bg_success': status.paymentStatus === 'PAID',
                                'bg-dark': !status.paymentStatus
                               }">
                                                        {{status.paymentStatus
                                                        ? status.paymentStatus: "N/A"}}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="status" *ngIf="isLoading">
                                    <mat-spinner style="margin:0 auto"></mat-spinner>
                                </div>
                            </div>
                        </div>
                        <div class="card col-sm-12">
                            <div class="card-header">
                                Balance
                            </div>
                            <div class="card-body">
                                <div class="blance">
                                    <div class="text">
                                        <h1>৳{{currentCustomerData?.customerBalance?.balance|number:'1.2-2'}}</h1>
                                        <p>user Current blance <a class="check_blance"
                                                (click)="getCustomerBlance()">Check balance</a> </p>
                                    </div>
                                    <div>
                                        <button mat-raised-button color="primary"
                                            (click)="openOrderDialog('partialPay')">Partial
                                            Pay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card col-sm-12">
                            <div class="card-header">
                                Delivery Instruction
                            </div>
                            <div class="card-body deliveryInstruction" *ngIf="!orderData?.deliveryInstruction">
                                <h5>No Delivery Instruction</h5>
                            </div>

                            <blockquote *ngIf="orderData?.deliveryInstruction" class="mt-2">
                                <p>{{orderData?.deliveryInstruction}}</p>
                            </blockquote>
                        </div>

                        <div class="card col-sm-12">
                            <div class="card-header">
                                Issues
                            </div>
                            <div class="card-body issue" *ngIf="orderData?.issue?.length == 0">
                                <h5>No issue found</h5>
                            </div>
                            <div class="card-body issue" *ngIf="orderData?.issue?.length > 0">
                                <div class="mt-2" *ngFor="let singleIssue of orderData.issue">
                                    <p>Create Issue At {{singleIssue?.createdAt|date:'medium':'+0600'}}</p>
                                    <blockquote style="cursor: pointer;" (click)="openOrderIssueChatModal(singleIssue)">
                                        <p>{{singleIssue.issue}}</p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</mat-drawer-container>

<mat-menu #searchMenu="matMenu">
    <button mat-menu-item (click)="drawer.toggle(); loadTransaction()">Transactional History</button>
</mat-menu>