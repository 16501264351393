import { AppState } from 'src/app/@state/app.state';
import { AuthService } from './../services/auth.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class AuthAccessDeniedGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private router: Router,
		private readonly store: Store<AppState>
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (!this.authService.isLoggedIn()) {
			return true;
		} else {
			this.router.navigate(['admin']);
			return false;
		}
	}
}
