<div style="width:800px">
  <mat-dialog-content>
    <div class="invoice-container" id="print-section">
      <!-- <header>
        <div class="row">
          <div class="col-5 mb-3 mb-sm-0">
            <img hieght="40px" style="height: 40px;" id="logo" src="assets/images/logo/logo.png"
              title="Gift of Gold" alt="Gift of Gold">
            <br>
            <h4 class="text-7 mb-0">Invoice</h4>
            <div>
              <img class="barcodeImg" id="barcode" />
            </div>
          </div>
          <div class="col-7 text-sm-right">
            <h5 class="text-5 mb-0">Gift of Gold</h5>
            <p>Address: Shop-69,Tatibazar, Puran Dhaka<br>
             info@giftofgold.shop<br>
              01970-501993</p>
          </div>
        </div>
        <hr>
      </header>
      <main>


        <div class="row">
          <div class="col-6 text-sm-right order-sm-1"> <strong>BILLING INFORMATION:</strong>
            <address>
              {{data?.order?.shippingAddress?.firstName|titlecase}} {{data?.order?.shippingAddress?.lastName|titlecase}}
              <br>
              {{data?.order?.billingAddress?.phoneNumber}}<br>
              {{data?.order?.shippingAddress?.address}}<br>
              {{data?.order?.shippingAddress?.city}}-{{data?.order?.shippingAddress?.zip}}<br>
            </address>
          </div>
          <div class="col-6 order-sm-0">
            <h4 class="text-5 mb-0">Invoice: {{data?.order?.orderCode}}</h4>

            <strong>Order Date &amp; Time:</strong> {{data?.order?.createdAt| date:'medium':'UTC'}}<br>

            <strong>Payment Method:</strong> {{data?.order?.paymentMethod}}<br>

          </div>

        </div>
        <div class="invoicecard">
          <div class="invoicecard-header px-2 py-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <td class="description border-0"><strong>Item Description</strong></td>
                    <td class="colsame text-center border-0"><strong>Rate</strong></td>
                    <td class="colhalf text-center border-0"><strong>QTY</strong></td>
                    <td class="colsame text-right border-0"><strong>Amount</strong></td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="invoicecard-body px-2 py-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                  <tr *ngFor="let product of data?.order.products">
                    <td class="description border-0">{{product?.name}}</td>
                    <td class="colsame text-center border-0">৳{{product.mrp |number:'1.2-2'}}</td>
                    <td class="colhalf text-center border-0">{{product.quantity}}</td>
                    <td class="colsame text-right border-0">৳{{(product.quantity * product.mrp)|number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Sub Total:</strong></td>
                    <td class="text-right">৳{{data?.order?.totalSubAmount |number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Vat(+):</strong></td>
                    <td class="text-right">৳{{data?.order.totalVat| number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Delivery Charge (+):</strong></td>
                    <td class="text-right">৳{{data?.order?.deliveryCharge |number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right green"><strong>Total:</strong></td>
                    <td class="text-right green">৳{{data?.order?.totalAmount |number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Paid:</strong></td>
                    <td class="text-right">৳{{data?.order?.paidAmount |number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Due:</strong></td>
                    <td class="text-right">৳{{data?.order?.dueAmount |number:'1.2-2'}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

        </div>
        <br>
        <div class="table-responsive d-print-none">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="text-center"><strong>Transaction Date</strong></td>
                <td class="text-center"><strong>Gateway</strong></td>
                <td class="text-center"><strong>Transaction ID</strong></td>
                <td class="text-center"><strong>Amount</strong></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let transaction of transactionHistory">
                <td class="text-center">{{transaction?.transactionTime|date}}</td>
                <td class="text-center">{{transaction?.transactionType}} </td>
                <td class="text-center">{{transaction?.transactionId}}</td>
                <td class="text-center">৳{{transaction?.amount| number:'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
      <footer class="text-center mt-4">
        <p class="text-1"><strong>NOTE :</strong> This is computer generated receipt and does not require physical
          signature.</p>
      </footer> -->
      <header>
        <div class="row align-items-center">
          <div class="col-5 text-center text-sm-left mb-sm-0">
            <img hieght="40px" style="height: 40px;" id="logo" src="assets/images/logo/logo.png"
              title="Gift of Gold" alt="Gift of Gold">
            <br> <br>
            <h2 class="mb-0">Gift of Gold</h2>
            <p>
              6/10, Akhi Nahar Goli, Tajmahal Road, <br>
              Mohammadpur, Dhaka - 1207<br>
              info@giftofgold.shop; 01970-501993<br>
              www.giftofgold.shop
            </p>
          </div>

          <div class="col-7 text-center text-sm-right">
            <div class="barcode"><img style="height: 90px; width: 204px;" class="barcodeImg" id="barcode" />
            </div>
            <br>
            <h2 class="text-5 mb-0">Invoice: {{data?.order?.orderCode}}</h2>
            <strong>Order Date &amp; Time:</strong> {{data?.order?.createdAt | date:'medium':'+0600'}}<br>
            <strong>Payment Method:</strong> {{data?.order?.paymentMethod}}<br>
          </div>
        </div>
        <hr>
      </header>

      <!-- Main Content -->
      <main>


        <div class="row">
          <div class="col-6 order-sm-0">
            <strong>BILL TO</strong>
            <address>
              <strong>{{data?.order?.shippingAddress?.firstName|titlecase}}
                {{data?.order?.shippingAddress?.lastName|titlecase}}</strong>
              <br>
              {{data?.order?.billingAddress?.phoneNumber}}<br>
              {{data?.order?.shippingAddress?.address}}<br>
              {{data?.order?.shippingAddress?.city}}-{{data?.order?.shippingAddress?.zip}}<br>
            </address>
          </div>
          <div class="col-6 text-sm-right order-sm-1">
            <strong>BILL FROM</strong>
            <address>
              <strong>{{currentShopVendor?.vendor?.firstName|titlecase}}
                {{currentShopVendor?.vendor?.lastName|titlecase}}</strong><br>
              {{currentShopVendor?.vendor?.phoneNumber}}<br>
              {{currentShopVendor?.address}}<br>
            </address>
          </div>
        </div>

        <div class="invoicecard">
          <div class="invoicecard-header px-2 py-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <td class="description border-0"><strong>Item Description</strong></td>
                    <td class="colsame text-center border-0"><strong>Rate</strong></td>
                    <td class="colhalf text-center border-0"><strong>QTY</strong></td>
                    <td class="colsame text-right border-0"><strong>Amount</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of data?.order.products">
                    <td class="description border-0">
                      <p> {{product?.name}}</p>
                      <span *ngIf="product.variants.length > 0">
                        <p *ngFor="let variant of product.variants">{{variant}}</p>
                      </span>
                    </td>
                    <td class="colsame text-center border-0">৳{{product.mrp |number:'1.2-2'}}</td>
                    <td class="colhalf text-center border-0">{{product.quantity}}</td>
                    <td class="colsame text-right border-0">৳{{(product.quantity * product.mrp)|number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Sub Total:</strong></td>
                    <td class="text-right">৳{{data?.order?.totalSubAmount |number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Vat(+):</strong></td>
                    <td class="text-right">৳{{data?.order.totalVat| number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right"><strong>Delivery Charge (+):</strong></td>
                    <td class="text-right">৳{{data?.order?.deliveryCharge |number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td rowspan="4" class="bg-light-2">
                      <img *ngIf="data?.order?.paymentStatus == 'PAID'"
                        style="height: 200px;text-align: center;display: block;margin: 0 auto;"
                        src="assets/images/Logo.png">
                      <img *ngIf="data?.order?.paymentStatus == 'UNPAID'"
                        style="height: 200px;text-align: center;display: block;margin: 0 auto;"
                        src="assets/images/Logo1.png">
                      <img *ngIf="data?.order?.paymentStatus != 'PAID' && data?.order?.paymentStatus != 'UNPAID' "
                        style="height: 200px;text-align: center;display: block;margin: 0 auto;"
                        src="assets/images/Logo2.png">
                    </td>


                    <td colspan="2" class="bg-light-2 text-right " style="color:blue; border-left: 1px solid #ddd;">
                      <strong>Total:</strong>
                    </td>
                    <td class="bg-light-2 text-right" style="color:blue">৳{{data?.order?.totalAmount |number:'1.2-2'}}
                    </td>

                  </tr>
                  <tr>
                    <td colspan="2" class="text-right green"><strong>Paid:</strong></td>
                    <td class=" text-right green">৳{{data?.order?.paidAmount |number:'1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right red"><strong>Due:</strong></td>
                    <td class="text-right red">৳{{data?.order?.dueAmount |number:'1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="text-center"><strong>Transaction Date</strong></td>
                <td class="text-center"><strong>Gateway</strong></td>
                <td class="text-center"><strong>Transaction ID</strong></td>
                <td class="text-center"><strong>Amount</strong></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let transaction of transactionHistory">
                <td class="text-center">{{transaction?.transactionTime|date}}</td>
                <td class="text-center">{{transaction?.transactionType}} </td>
                <td class="text-center">{{transaction?.transactionId}}</td>
                <td class="text-center">৳{{transaction?.amount| number:'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
      <!-- Footer -->
      <footer class="text-center mt-4">
        <p class="text-1"><strong>NOTE :</strong> This is computer generated receipt and does not require physical
          signature.</p>
      </footer>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCEL</button>
    <button color="primary" [useExistingCss]="true" printSectionId="print-section" ngxPrint mat-button>PRINT</button>
  </mat-dialog-actions>
</div>