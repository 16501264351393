import {
	changePasswordSuccess,
	loadCurrentUserSuccess,
	logout,
} from './current-user.action';
import { CurrentUserInitialState } from './current-user.state';
import { createReducer, on } from '@ngrx/store';

const _currentUserReducer = createReducer(
	CurrentUserInitialState,
	on(loadCurrentUserSuccess, (state, action) => {
		return {
			...state,
			currentUser: action.currentUser,
			jwtToken: action.jwtToken,
			permission: action.permission,
		};
	}),
	on(changePasswordSuccess, (state, action) => {
		return {
			...state,
		};
	}),
	on(logout, (state, action) => {
		return { ...state };
	})
);

export const currentUserReducer = (state, action) => {
	return _currentUserReducer(state, action);
};
