import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderIssueChatService } from './../../../@shared/services/orderIssueChat.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@UntilDestroy()
@Component({
	selector: 'app-order-issue-chat',
	templateUrl: './order-issue-chat.component.html',
	styleUrls: ['./order-issue-chat.component.scss'],
})
export class OrderIssueChatComponent implements OnInit {
	form: FormGroup;
	orderIssueId: string;

	chats: any[] = [];

	constructor(
		public dialogRef: MatDialogRef<OrderIssueChatComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private orderIssueChatService: OrderIssueChatService
	) {
		this.orderIssueId = data.issueId;
	}

	ngOnInit() {
		this.setForm();
		this.getData();
	}

	getData(e?): void {
		this.orderIssueChatService
			.filter({ orderIssue: this.orderIssueId })
			.pipe(untilDestroyed(this))
			.subscribe((res) => {
				this.chats = res.data;
			});
	}

	setForm() {
		this.form = this.fb.group({
			chat: [''],
			orderIssue: [this.orderIssueId],
		});
	}

	sendMessage() {
		this.orderIssueChatService
			.create(this.form.value)
			.pipe(untilDestroyed(this))
			.subscribe((res) => {
				if (res.success) {
					this.chats.push(res.data);
				}
			});

		this.setForm();
	}
}
