import { Permission } from './../../@shared/model/permission.model';
import { CurrentUser } from './../../@shared/model/currentUser.model';

export interface CurrentUserState {
	currentUser: CurrentUser;
	jwtToken: string;
	permission: Permission[];
}

export const CurrentUserInitialState: CurrentUserState = {
	currentUser: {},
	jwtToken: '',
	permission: [],
};
