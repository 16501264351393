import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { OrderStatusMessageService } from 'src/app/@shared/services/orderStatusMessage.service';
import { OrderStatusMessage } from 'src/app/@shared/model/orderStatusMessage.model';

@UntilDestroy()
@Component({
	selector: 'app-order-dialog',
	templateUrl: './order-dialog.component.html',
	styleUrls: ['./order-dialog.component.scss'],
})
export class OrderDialogComponent implements OnInit {
	pageTitle: string;
	form: FormGroup;

	custom = new FormControl('');

	orderId: string;

	orderStatusMessage: OrderStatusMessage[];

	constructor(
		public dialogRef: MatDialogRef<OrderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private orderStatusMessageService: OrderStatusMessageService
	) {}

	ngOnInit(): void {
		this.pageTitle = this.data.pageTitle;
		this.orderId = this.data.orderId;
		this.enableForm();
		this.getOrderStatusMessage();
	}

	enableForm() {
		if (this.pageTitle === 'partialPay') {
		} else {
			this.form = this.fb.group({
				status: [this.data.pageTitle, [Validators.required]],
				order: [this.orderId],
				comments: ['', [Validators.required]],
			});
		}
	}

	getOrderStatusMessage() {
		this.orderStatusMessageService
			.filter({
				isActive: true,
				status: this.pageTitle,
			})
			.pipe(untilDestroyed(this))
			.subscribe((orderStatusMessage) => {
				this.orderStatusMessage = orderStatusMessage.data;
			});
	}

	save() {
		const data = { ...this.form.value };
		if (this.custom.value) {
			data.comments = this.custom.value;
		}
		this.dialogRef.close({ data });
	}
}
