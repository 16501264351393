import { OrderIssueChatComponent } from './../order-issue-chat/order-issue-chat.component';
import { OrderNoteService } from './../../../@shared/services/orderNote.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TransactionsService } from './../../../@shared/services/transactions.service';
import { NotificationService } from './../../../@shared/services/notification.service';
import { OrderPaymentStatusComponent } from './../order-payment-status/order-payment-status.component';
import { OrderStatus } from './../../../@shared/model/orderStatus.model';
import { OrderStatusService } from './../../../@shared/services/orderStatus.service';
import { OrderInvoiceComponent } from './../order-invoice/order-invoice.component';
import { CustomerService } from './../../../@shared/services/customer.service';
import { Customer } from './../../../@shared/model/customer.model';
import { OrderService } from './../../../@shared/services/order.service';
import { getOrder } from './../../../@state/order/order.selector';
import { getCurrentRouteId } from './../../../@state/router/router.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderDialogComponent } from './../order-dialog/order-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/@state/app.state';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	currentOrderId: string;
	orderData: any = {};
	currentCustomerData: Customer;

	orderStatus: OrderStatus[] = [];
	transactionHistory: any[] = [];

	noteForm: FormGroup;
	isLoading: boolean = false;

	constructor(
		private router: Router,
		public dialog: MatDialog,
		private store: Store<AppState>,
		private orderService: OrderService,
		private orderStatusService: OrderStatusService,
		private orderNoteService: OrderNoteService,
		private customarService: CustomerService,
		private notificationService: NotificationService,
		private readonly fb: FormBuilder,
		private readonly transactionsService: TransactionsService
	) {}

	ngOnInit(): void {
		this.setNoteForm();

		this.getCurrentOrderId();
		this.getOrderData(this.currentOrderId);

		if (!this.orderData) {
			this.orderService
				.getById(this.currentOrderId)
				.pipe(untilDestroyed(this))
				.subscribe((res: any) => {
					this.orderData = res.data;
					this.getOrderStatus(this.currentOrderId);
					this.getCustomerBlance();
				});
		}
	}

	setNoteForm() {
		this.noteForm = this.fb.group({
			note: [''],
		});
	}

	getCustomerBlance() {
		this.customarService
			.getById(this.orderData.customer.id)
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.currentCustomerData = res.data;
			});
	}

	getCurrentOrderId() {
		this.store
			.select(getCurrentRouteId)
			.pipe(untilDestroyed(this))
			.subscribe((id) => {
				this.currentOrderId = id;
			});
	}

	getOrderData(id: string) {
		this.store
			.select(getOrder)
			.pipe(untilDestroyed(this))
			.subscribe((state) => {
				let orderData = state.orders.find((data) => data.id === id);
				this.orderData = orderData;
				if (orderData) {
					this.getOrderStatus(this.currentOrderId);
					this.getCustomerBlance();
				}
			});
	}

	openOrderDialog(pageTitle: string) {
		const dialogRef = this.dialog.open(OrderDialogComponent, {
			data: {
				pageTitle,
				orderId: this.currentOrderId,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.addOrderStatus(result.data);
			}
		});
	}

	updateOrderData() {
		this.orderService
			.getById(this.currentOrderId)
			.pipe(untilDestroyed(this))
			.subscribe((data: any) => {
				this.orderData = data.data;
			});
	}

	getOrderStatus(orderId: string) {
		this.isLoading = true;
		this.orderStatusService
			.filter({ order: orderId })
			.pipe(untilDestroyed(this))
			.subscribe((orderStatus) => {
				this.orderStatus = orderStatus.data;
				this.isLoading = false;
			});
	}

	addOrderStatus(data) {
		this.isLoading = true;
		this.orderStatusService
			.create(data)
			.pipe(untilDestroyed(this))
			.subscribe((orderStatus) => {
				this.orderStatus = [orderStatus.data, ...this.orderStatus];
				this.updateOrderData();
				this.isLoading = false;
			});
	}

	openInvoice() {
		this.dialog
			.open(OrderInvoiceComponent, {
				data: {
					order: this.orderData,
				},
			})
			.afterClosed()
			.pipe(untilDestroyed(this))
			.subscribe((data: any) => {
				if (data) {
				}
			});
	}

	goBack() {
		this.router.navigate(['/admin/orders']);
	}

	changeOrderPaymentStatus(row: any) {
		this.dialog
			.open(OrderPaymentStatusComponent, {
				data: {
					mode: 'paymentStatus',
					paymentStatus: row.paymentStatus,
				},
			})
			.afterClosed()
			.pipe(untilDestroyed(this))
			.subscribe((data: any) => {
				if (data) {
					this.orderService
						.update(this.currentOrderId, data)
						.pipe(untilDestroyed(this))
						.subscribe((res: any) => {
							if (res.success) {
								this.notificationService.showPopupSuccess(
									'Payment Status Updated !!'
								);
								this.orderData = res.data;
							}
						});
				}
			});
	}

	changeOrderAddress(row: any) {
		this.dialog
			.open(OrderPaymentStatusComponent, {
				data: {
					mode: 'orderAddress',
					address: row.shippingAddress.address,
				},
			})
			.afterClosed()
			.pipe(untilDestroyed(this))
			.subscribe((data: any) => {
				if (data) {
					let payload = {
						shippingAddress: { ...row.shippingAddress, address: data.address },
					};
					this.orderService
						.update(this.currentOrderId, payload)
						.pipe(untilDestroyed(this))
						.subscribe((res: any) => {
							if (res.success) {
								this.orderData = res.data;
								this.notificationService.showPopupSuccess('Address Updated ..');
							}
						});
				}
			});
	}

	sendNote() {
		const payload = { ...this.noteForm.value, order: this.currentOrderId };
		this.orderNoteService
			.create(payload)
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.orderData.note.push(res.data);
				this.noteForm.reset();
			});
	}

	loadTransaction() {
		this.transactionsService
			.filter({ orderCode: this.orderData.orderCode })
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.transactionHistory = res.data;
			});
	}

	openOrderIssueChatModal(issue) {
		this.dialog
			.open(OrderIssueChatComponent, {
				data: {
					issueId: issue.id,
				},
			})
			.afterClosed()
			.pipe(untilDestroyed(this))
			.subscribe();
	}
}
