import { BehaviorSubject, Subject, fromEvent } from 'rxjs'
import { Injectable, OnDestroy } from '@angular/core'
import { debounceTime, takeUntil } from 'rxjs/operators'

import { Router } from '@angular/router'

// Menu
export interface Menu {
	headTitle1?: string
	headTitle2?: string
	path?: string
	title?: string
	icon?: string
	type?: string
	badgeType?: string
	badgeValue?: string
	active?: boolean
	bookmark?: boolean
	children?: Menu[]
}
@Injectable({
	providedIn: 'root',
})
export class NavService implements OnDestroy {
	private unsubscriber: Subject<any> = new Subject()
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
		window.innerWidth
	)
	// Search Box
	public search: boolean = false
	// Language
	public language: boolean = false
	// Mega Menu
	public megaMenu: boolean = false
	public levelMenu: boolean = false
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false
	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false
	// Full screen
	public fullScreen: boolean = false
	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth)
		fromEvent(window, 'resize')
			.pipe(debounceTime(1000), takeUntil(this.unsubscriber))
			.subscribe((evt: any) => {
				this.setScreenWidth(evt.target.innerWidth)
				if (evt.target.innerWidth < 991) {
					this.collapseSidebar = true
					this.megaMenu = false
					this.levelMenu = false
				}
				if (evt.target.innerWidth < 1199) {
					this.megaMenuColapse = true
				}
			})
		if (window.innerWidth < 991) {
			// Detect Route change sidebar close
			this.router.events.subscribe((event) => {
				this.collapseSidebar = true
				this.megaMenu = false
				this.levelMenu = false
			})
		}
	}
	ngOnDestroy() {
		this.unsubscriber.next()
		this.unsubscriber.complete()
	}
	private setScreenWidth(width: number): void {
		this.screenWidth.next(width)
	}
	MENUITEMS: Menu[] = [
		{
			path: '/admin/dashboard',
			title: 'Dashboard',
			icon: 'trending-up',
			type: 'link',
		},
		// {
		// 	path: '/admin/analytics',
		// 	title: 'Analytics',
		// 	icon: 'trending-up',
		// 	type: 'link',
		// },
		{
			path: '/admin/orders',
			title: 'Orders',
			icon: 'home',
			type: 'link',
		},
		{
			path: '/admin/badges',
			title: 'Badges',
			icon: 'home',
			type: 'link',
		},
		// {
		// 	path: '/admin/shops',
		// 	title: 'Shops',
		// 	icon: 'shopping-bag',
		// 	type: 'link',
		// },
		{
			path: '/admin/products',
			title: 'Products',
			icon: 'package',
			type: 'link',
		},
		{
			path: '/admin/customer',
			title: 'Customers',
			icon: 'users',
			type: 'link',
		},
		// {
		// 	path: '/admin/vendors',
		// 	title: 'Vendors',
		// 	icon: 'users',
		// 	type: 'link',
		// },
		{
			path: '/admin/campaign',
			title: 'Campaigns',
			icon: 'calendar',
			type: 'link',
		},
		{
			path: '/admin/gift-card',
			title: 'GiftCards',
			icon: 'gift',
			type: 'link',
		},
		{
			path: '/admin/deal-of-the-day',
			title: 'Deal Of The Day',
			icon: 'triangle',
			type: 'link',
		},
		// {
		// 	path: '/admin/balance',
		// 	title: 'Balance Add & Deduct',
		// 	icon: 'dollar-sign',
		// 	type: 'link',
		// },
		{
			path: '/admin/banner',
			title: 'Banners',
			icon: 'airplay',
			type: 'link',
		},
		// {
		// 	path: '/admin/marketing',
		// 	title: 'Marketings',
		// 	icon: 'compass',
		// 	type: 'link',
		// },
		{
			path: '/admin/transactions',
			title: 'Transactions',
			icon: 'compass',
			type: 'link',
		},
		// {
		// 	path: '/admin/grocery-shop',
		// 	title: 'Grocery Shop',
		// 	icon: 'shopping-bag',
		// 	type: 'link',
		// },
		// {
		// 	path: '/admin/grocery-order',
		// 	title: 'Grocery Orders',
		// 	icon: 'shopping-cart',
		// 	type: 'link',
		// },
		{
			title: 'Settings',
			icon: 'settings',
			type: 'sub',
			children: [
				{
					path: '/admin/brands',
					title: 'Brands',
					icon: 'tag',
					type: 'link',
				},
				{
					path: '/admin/departments',
					title: 'Departments',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/categories',
					title: 'Categories',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/sub-category',
					title: 'Sub Category',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/discount',
					title: 'Discount',
					icon: 'percent',
					type: 'link',
				},
				{
					path: '/admin/shipping-method',
					title: 'Shipping Methods',
					icon: 'truck',
					type: 'link',
				},
				{
					path: '/admin/user',
					title: 'User',
					icon: 'user',
					type: 'link',
				},
				// {
				// 	path: '/admin/user-permission',
				// 	title: 'User Permission',
				// 	icon: 'tool',
				// 	type: 'link',
				// },
				{
					path: '/admin/refund',
					title: 'Refund Process',
					icon: 'rotate-ccw',
					type: 'link',
				},
				// {
				// 	path: '/admin/seller-bill',
				// 	title: 'Seller Bills',
				// 	icon: 'archive',
				// 	type: 'link',
				// },
				{
					path: '/admin/footer',
					title: 'Footer',
					icon: 'square',
					type: 'link',
				},
				{
					path: '/admin/page-create',
					title: 'Single Page',
					icon: 'file-plus',
					type: 'link',
				},
				{
					path: '/admin/payment-system',
					title: 'Payment System',
					icon: 'credit-card',
					type: 'link',
				},
				{
					path: '/admin/countries',
					title: 'Imported From',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/promotion',
					title: 'Promotion',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/shop-type',
					title: 'Shop Types',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/varient',
					title: 'Varient',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/varient-option',
					title: 'Varient Option',
					icon: 'triangle',
					type: 'link',
				},
				// {
				// 	path: '/admin/sms-body',
				// 	title: 'SMS Body',
				// 	icon: 'triangle',
				// 	type: 'link',
				// },
				{
					path: '/admin/order-message',
					title: 'Order Status Message',
					icon: 'triangle',
					type: 'link',
				},
				{
					path: '/admin/configs',
					title: 'Config',
					icon: 'tool',
					type: 'link',
				},
			],
		},
		// {
		// 	path: '/admin/settings',
		// 	title: 'Settings',
		// 	icon: 'settings',
		// 	type: 'link',
		// },
	]
	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages',
			type: 'sub',
			active: true,
			children: [
				{
					path: 'javascript:void(0);',
					title: 'Error Page 400',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Error Page 401',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Error Page 403',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Error Page 404',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Error Page 500',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Error Page 503',
					type: 'extLink',
				},
			],
		},
		{
			title: 'Authentication',
			type: 'sub',
			active: false,
			children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{
					path: 'javascript:void(0);',
					title: 'Login BG Image',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Login BG Video',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Simple Register',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Register BG Image',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Register BG Video',
					type: 'extLink',
				},
			],
		},
		{
			title: 'Usefull Pages',
			type: 'sub',
			active: false,
			children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{
					path: 'javascript:void(0);',
					title: 'Forgot Password',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Reset Password',
					type: 'extLink',
				},
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' },
			],
		},
		{
			title: 'Email templates',
			type: 'sub',
			active: false,
			children: [
				{
					path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html',
					title: 'Basic Email',
					type: 'extTabLink',
				},
				{
					path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html',
					title: 'Basic With Header',
					type: 'extTabLink',
				},
				{
					path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html',
					title: 'Ecomerce Template',
					type: 'extTabLink',
				},
				{
					path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html',
					title: 'Email Template 2',
					type: 'extTabLink',
				},
				{
					path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html',
					title: 'Ecommerce Email',
					type: 'extTabLink',
				},
				{
					path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html',
					title: 'Order Success',
					type: 'extTabLink',
				},
			],
		},
		{
			title: 'Coming Soon',
			type: 'sub',
			active: false,
			children: [
				{
					path: 'javascript:void(0);',
					title: 'Coming Simple',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Coming BG Image',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Coming BG Video',
					type: 'extLink',
				},
			],
		},
	]
	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);',
			title: 'File Manager',
			icon: 'git-pull-request',
			type: 'extLink',
		},
		{
			title: 'Users',
			icon: 'users',
			type: 'sub',
			active: false,
			children: [
				{
					path: 'javascript:void(0);',
					title: 'All Users',
					icon: 'users',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'User Profile',
					icon: 'users',
					type: 'extLink',
				},
				{
					path: 'javascript:void(0);',
					title: 'Edit Profile',
					icon: 'users',
					type: 'extLink',
				},
			],
		},
		{
			path: 'javascript:void(0);',
			title: 'Bookmarks',
			icon: 'heart',
			type: 'extLink',
		},
		{
			path: 'javascript:void(0);',
			title: 'Calender',
			icon: 'calendar',
			type: 'extLink',
		},
		{
			path: 'javascript:void(0);',
			title: 'Social App',
			icon: 'zap',
			type: 'extLink',
		},
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS)
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS)
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS)
}
