<form [formGroup]="form" style="width:600px">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Order Issue Chat</h2>
  </div>

  <mat-divider></mat-divider>

  <mat-dialog-content>
    <ng-container>
      <div class="card-body issue" *ngIf="chats.length == 0">
        <h3 style="text-align:center;">No chats found</h3>
      </div>
      <div class="card-body" *ngIf="chats.length > 0">
        <div class="mt-2" *ngFor="let singleChat of chats">
          <p>Message At {{singleChat?.createdAt|date:'medium':'+0600'}}</p>
          <blockquote>
            <p>{{singleChat.chat}}</p>
          </blockquote>
        </div>
      </div>

    </ng-container>


  </mat-dialog-content>

  <mat-dialog-actions>
    <mat-form-field appearance="fill" style="width: 100%;">
      <textarea cdkTextareaAutosize matInput formControlName="chat" (keyup.control.enter)="sendMessage()"></textarea>
      <button matSuffix mat-icon-button color="primary" (click)="sendMessage()" [disabled]="form.pristine">
        <mat-icon class="cursor">send</mat-icon>
      </button>
    </mat-form-field>
  </mat-dialog-actions>
</form>