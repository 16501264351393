<form [formGroup]="form" style="width:800px">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Report Download</h2>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>

    <mat-tab-group (selectedTabChange)="tabChange($event)">
      <mat-tab label="Master Summary">
        <ng-container>
          <div class="full_width mt-4">
            <mat-form-field class="half_width" appearance="outline">
              <mat-label>Shop</mat-label>
              <mat-select formControlName="shop">
                <mat-option>
                  <ngx-mat-select-search [formControl]="shopCtrl" placeholderLabel="Search Shop"
                    noEntriesFoundLabel="no matching Shop found" (ngModelChange)="shopSearch()">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let shop of allShop" [value]="shop.id">
                  <span>{{ shop.name }}</span>
                </mat-option>
              </mat-select>
              <mat-error><strong>Shop</strong> is required</mat-error>
            </mat-form-field>


            <mat-form-field class="half_width" appearance="outline">
              <mat-label>Payment Method</mat-label>
              <mat-select formControlName="paymentMethod">
                <!-- <mat-select formControlName="paymentMethod" multiple required> -->
                <!-- <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip *ngFor="let payment of allPaymentMethod;let i = index" [removable]="true"
                        (removed)="onPaymentMethodRemoved(i)">
                        {{ payment.name }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger> -->

                <mat-option>
                  <ngx-mat-select-search [formControl]="paymentCtrl" placeholderLabel="Search Payment Method"
                    noEntriesFoundLabel="no matching Payment Method found" (ngModelChange)="paymentSearch()">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let payment of allPaymentMethod" [value]="payment.id">
                  <span>{{ payment.name }}</span>
                </mat-option>
              </mat-select>
              <mat-error><strong>Payment Method</strong> is required</mat-error>
            </mat-form-field>
          </div>




          <mat-form-field appearance="outline" class="full_width">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput required>
            <mat-error *ngIf="form.get('email').hasError('required')"><strong>Email</strong> is Required</mat-error>
            <mat-error *ngIf="form.get('email').hasError('pattern')"><strong>Email</strong> is Invalid</mat-error>
          </mat-form-field>

          <div class="full_width">
            <mat-form-field appearance="outline" class="half_width">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="startDate" [max]="today()" required>
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="form.get('startDate').hasError('required')"><strong>Start Date</strong> is Required
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="half_width">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="endPicker" formControlName="endDate"
                [readonly]="!form.get('startDate').value" [min]="form.get('startDate').value" required>
              <mat-datepicker-toggle matSuffix [for]="endPicker" [disabled]="!form.get('startDate').value">
              </mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
              <mat-error *ngIf="form.get('endDate').hasError('required')"><strong>End Date</strong> is Required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="full_width">
            <mat-form-field appearance="outline" class="half_width">
              <mat-label>Payment Status</mat-label>
              <mat-select formControlName="paymentStatus">
                <!-- <mat-select formControlName="paymentStatus" multiple> -->
                <!-- <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip *ngFor="let payment of form.get('paymentStatus').value;let i = index" [removable]="true"
                        (removed)="onPaymentStatusRemoved(i)">
                        {{ payment }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger> -->

                <mat-option value="PAID">PAID</mat-option>
                <mat-option value="PARTIAL PAID">PARTIAL PAID</mat-option>
                <mat-option value="UNPAID">UNPAID</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="half_width">
              <mat-label>Order Status</mat-label>
              <mat-select formControlName="orderStatus">
                <!-- <mat-select formControlName="orderStatus" multiple> -->
                <!-- <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip *ngFor="let order of form.get('orderStatus').value;let i = index" [removable]="true"
                        (removed)="onOrderRemoved(i)">
                        {{ order }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger> -->

                <mat-option *ngFor="let status of allOrderStatus" [value]="status">{{status}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </ng-container>
      </mat-tab>


      <mat-tab label="Monthly Summary">
        <ng-container>
          <div class="full_width mt-4">
            <mat-form-field class="half_width" appearance="outline">
              <mat-label>Shop</mat-label>
              <mat-select formControlName="shop">
                <mat-option>
                  <ngx-mat-select-search [formControl]="shopCtrl" placeholderLabel="Search Shop"
                    noEntriesFoundLabel="no matching Shop found" (ngModelChange)="shopSearch()">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let shop of allShop" [value]="shop.id">
                  <span>{{ shop.name }}</span>
                </mat-option>
              </mat-select>
              <mat-error><strong>Shop</strong> is required</mat-error>
            </mat-form-field>

            <mat-form-field class="half_width" appearance="outline">
              <mat-label>Payment Method</mat-label>
              <mat-select formControlName="paymentMethod">
                <!-- <mat-select formControlName="paymentMethod" multiple required> -->
                <!-- <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip *ngFor="let payment of allPaymentMethod;let i = index" [removable]="true"
                        (removed)="onPaymentMethodRemoved(i)">
                        {{ payment.name }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger> -->

                <mat-option>
                  <ngx-mat-select-search [formControl]="paymentCtrl" placeholderLabel="Search Payment Method"
                    noEntriesFoundLabel="no matching Payment Method found" (ngModelChange)="paymentSearch()">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let payment of allPaymentMethod" [value]="payment.id">
                  <span>{{ payment.name }}</span>
                </mat-option>
              </mat-select>
              <mat-error><strong>Payment Method</strong> is required</mat-error>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="full_width">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput required>
            <mat-error *ngIf="form.get('email').hasError('required')"><strong>Email</strong> is Required</mat-error>
            <mat-error *ngIf="form.get('email').hasError('pattern')"><strong>Email</strong> is Invalid</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full_width">
            <mat-label>Month</mat-label>
            <input matInput type="month" formControlName="startDate" required (ngModelChange)='onDateSelect()'>
            <mat-error *ngIf="form.get('startDate').hasError('required')"><strong>Month</strong> is Required</mat-error>
          </mat-form-field>

          <!-- <div class="full_width">
            <mat-form-field appearance="outline" class="half_width">
              <mat-label>Start Month</mat-label>
              <input matInput type="month" formControlName="startDate">
            </mat-form-field>

            <mat-form-field appearance="outline" class="half_width">
              <mat-label>End Month</mat-label>
              <input matInput type="month" formControlName="endDate" [readonly]="!form.get('startDate').value"
                [min]="form.get('startDate').value">

            </mat-form-field>
          </div> -->

          <div class="full_width">
            <mat-form-field appearance="outline" class="half_width">
              <mat-label>Payment Status</mat-label>
              <mat-select formControlName="paymentStatus">
                <!-- <mat-select formControlName="paymentStatus" multiple> -->
                <!-- <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip *ngFor="let payment of form.get('paymentStatus').value;let i = index" [removable]="true"
                        (removed)="onPaymentStatusRemoved(i)">
                        {{ payment }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger> -->

                <mat-option value="PAID">PAID</mat-option>
                <mat-option value="PARTIAL PAID">PARTIAL PAID</mat-option>
                <mat-option value="UNPAID">UNPAID</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="half_width">
              <mat-label>Order Status</mat-label>
              <mat-select formControlName="orderStatus">
                <!-- <mat-select formControlName="orderStatus" multiple> -->
                <!-- <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip *ngFor="let order of form.get('orderStatus').value;let i = index" [removable]="true"
                        (removed)="onOrderRemoved(i)">
                        {{ order }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger> -->

                <mat-option *ngFor="let status of allOrderStatus" [value]="status">{{status}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </ng-container>
      </mat-tab>
      <mat-tab label="Active Customer"> Content 3 </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCEL</button>
    <button (click)="save()" color="primary" mat-button type="submit" [disabled]="form.invalid">SEND</button>
  </mat-dialog-actions>
</form>