import { ShopService } from 'src/app/@shared/services/shop.service';
import { TransactionsService } from './../../../@shared/services/transactions.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit, Inject } from '@angular/core';
import * as JsBarcode from 'jsbarcode';

@UntilDestroy()
@Component({
	selector: 'app-order-invoice',
	templateUrl: './order-invoice.component.html',
	styleUrls: ['./order-invoice.component.scss'],
})
export class OrderInvoiceComponent implements OnInit {
	transactionHistory: any[] = [];
	currentShopVendor: any;
	constructor(
		public dialogRef: MatDialogRef<OrderInvoiceComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private readonly transactionsService: TransactionsService,
		private readonly shopService: ShopService
	) {
		this.transactionsService
			.filter({ orderCode: this.data.order.orderCode })
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.transactionHistory = res.data;
			});

		this.shopService
			.getById(this.data.order.shop.id)
			.pipe(untilDestroyed(this))
			.subscribe((res: any) => {
				this.currentShopVendor = res.data;
				console.log(this.currentShopVendor);
			});
	}

	ngOnInit() {
		this.genetateBarCode();
	}

	genetateBarCode(): void {
		const orderCode = this.data.order.orderCode;
		JsBarcode('#barcode', orderCode, {
			height: 50,
			displayValue: false,
		});
	}
}
