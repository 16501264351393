import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class OrderNoteService {
	protected readonly EndPoint = environment.API_ENDPOINT + 'orderNotes/';

	constructor(protected readonly http: HttpClient) {}

	filter(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
			order?: string;
		} = {}
	): Observable<any> {
		return this.http.get(
			`${this.EndPoint}?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}&order=${option.order || ''}`
		);
	}

	create(payload: any): Observable<any> {
		return this.http.post(this.EndPoint, payload);
	}
}
