import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class ShopService extends BaseService {
	protected readonly EndPoint = environment.API_ENDPOINT + 'shops/';

	constructor(protected readonly http: HttpClient) {
		super(http);
	}

	filter(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
			shopType?: string;
			vendor?: string;
			isActive?: any;
		} = {}
	): Observable<any> {
		return this.http.get(
			`${this.EndPoint}filter?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}&shopType=${
				option.shopType || ''
			}&vendor=${option.vendor || ''}&isActive=${option.isActive}`
		);
	}

	deleteMany(ids: string[]): Observable<any> {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: ids,
		};
		return this.http.delete(this.EndPoint + 'bulkDelete', options);
	}

	updateShopActiveStatus(id: string, payload: any): Observable<any> {
		return this.http.put(`${this.EndPoint}isActive/${id}`, payload);
	}
}
