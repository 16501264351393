import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-order-payment-status',
	templateUrl: './order-payment-status.component.html',
	styleUrls: ['./order-payment-status.component.scss'],
})
export class OrderPaymentStatusComponent implements OnInit {
	form: FormGroup;
	pageTitle: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<OrderPaymentStatusComponent>,
		private fb: FormBuilder
	) {}

	ngOnInit() {
		if (this.data.mode === 'paymentStatus') {
			this.enablePaymentForm();
			this.pageTitle = 'Update Payment Status';
		} else if (this.data.mode === 'orderAddress') {
			this.enableAddressForm();
			this.pageTitle = 'Update Order Address';
		}
	}

	enablePaymentForm() {
		this.form = this.fb.group({
			paymentStatus: [this.data.paymentStatus, [Validators.required]],
		});
	}

	enableAddressForm() {
		this.form = this.fb.group({
			address: [this.data.address, [Validators.required]],
		});
	}

	save() {
		this.dialogRef.close(this.form.value);
	}
}
