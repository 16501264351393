import { untilDestroyed } from '@ngneat/until-destroy';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { AuthService } from './../../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { CurrentUser } from 'src/app/@shared/model/currentUser.model';
import { AppState } from 'src/app/@state/app.state';
import { Store } from '@ngrx/store';
import { getCurrentUserSelector } from 'src/app/@state/current-user/current-user.selector';

@UntilDestroy()
@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
	currentUser: CurrentUser;

	constructor(
		private authService: AuthService,
		private router: Router,
		private store: Store<AppState>
	) {}

	ngOnInit() {
		this.store
			.select(getCurrentUserSelector)
			.pipe(untilDestroyed(this))
			.subscribe((currentUser: CurrentUser) => {
				if (currentUser) {
					this.currentUser = currentUser;
				}
			});
	}

	userLogOut() {
		this.authService.logout();
		this.router.navigate(['/login']);
	}
}
