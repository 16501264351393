import { MatTabChangeEvent } from '@angular/material/tabs';
import { PaymentSystemService } from './../../../@shared/services/payment-system.service';
import { ShopService } from './../../../@shared/services/shop.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Shop } from '../../../@shared/model/shop.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormControl,
} from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-order-export-excel',
	templateUrl: './report-download.component.html',
	styleUrls: ['./report-download.component.scss'],
})
export class ReportDownloadComponent implements OnInit {
	form: FormGroup;

	allOrderStatus: any[] = [
		'PENDING',
		'PROCESSING',
		'SHIPPED',
		'DELIVERED',
		'CANCEL',
	];

	shopSearchSubject: Subject<string> = new Subject<string>();
	shopCtrl = new FormControl();
	paymentSearchSubject: Subject<string> = new Subject<string>();
	paymentCtrl = new FormControl();

	allShop: Shop[] = [];
	allPaymentMethod: Shop[] = [];

	currentOpenTab = 0;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<ReportDownloadComponent>,
		private fb: FormBuilder,
		private readonly shopService: ShopService,
		private readonly paymentSystemService: PaymentSystemService
	) {}

	ngOnInit(): void {
		this.shopSearchDebounce();
		this.paymentMethodSearchDebounce();

		this.getShop();
		this.getPaymentMethod();
		this.enableForm();
	}

	save(): void {
		if (this.currentOpenTab == 1) {
			const date = new Date(this.form.get('startDate').value);

			const month = date.getMonth();
			const year = date.getFullYear();

			const startDate = new Date(year, month, 1);
			const endDate = new Date(year, month + 1, 0, 23, 59, 59);

			this.form.get('startDate').setValue(startDate);
			this.form.get('endDate').setValue(endDate);
		}

		this.dialogRef.close({
			data: this.form.value,
			lastSelectedTab: this.currentOpenTab,
		});
	}

	enableForm() {
		this.form = this.fb.group({
			shop: [''],
			paymentMethod: [''],
			email: [
				'',
				[
					Validators.required,
					Validators.pattern(
						/^([a-zA-Z0-9]+)@([a-zA-z0-9]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
					),
				],
			],
			startDate: ['', [Validators.required]],
			endDate: ['', [Validators.required]],
			paymentStatus: [[]],
			orderStatus: [[]],
		});
	}

	getShop(e?) {
		this.shopService
			.filter({ ...e, isActive: true })
			.pipe(untilDestroyed(this))
			.subscribe((shop) => {
				this.allShop = shop.data;
			});
	}

	shopSearchDebounce(): void {
		this.shopSearchSubject
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this))
			.subscribe((value: string) => {
				this.getShop({ searchTerm: value });
			});
	}

	shopSearch() {
		this.shopSearchSubject.next(this.shopCtrl.value);
	}

	getPaymentMethod(e?) {
		this.paymentSystemService
			.filter({ ...e, isActive: true })
			.pipe(untilDestroyed(this))
			.subscribe((payment) => {
				this.allPaymentMethod = payment.data;
			});
	}

	paymentMethodSearchDebounce(): void {
		this.paymentSearchSubject
			.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this))
			.subscribe((value: string) => {
				this.getPaymentMethod({ searchTerm: value });
			});
	}

	paymentSearch() {
		this.paymentSearchSubject.next(this.paymentCtrl.value);
	}

	today() {
		return new Date();
	}

	tabChange(tabChangeEvent: MatTabChangeEvent): void {
		this.currentOpenTab = tabChangeEvent.index;
	}

	onDateSelect() {
		this.form.get('endDate').setValue(new Date());
	}

	// onPaymentMethodRemoved(index: number) {
	// 	const paymentMethod = this.form.get('paymentMethod').value as string[];
	// 	paymentMethod.splice(index, 1);
	// 	this.form.get('paymentMethod').setValue(paymentMethod);
	// }

	// onPaymentStatusRemoved(index: number) {
	// 	const paymentStatus = this.form.get('paymentStatus').value as string[];
	// 	paymentStatus.splice(index, 1);
	// 	this.form.get('paymentStatus').setValue(paymentStatus);
	// }

	// onOrderRemoved(index: number) {
	// 	const orderStatus = this.form.get('orderStatus').value as string[];
	// 	orderStatus.splice(index, 1);
	// 	this.form.get('orderStatus').setValue(orderStatus);
	// }
}
