<form [formGroup]="form" style="width:500px">
  <h2 class="headline m-0" fxFlex="auto">{{pageTitle}}</h2>

  <mat-dialog-content>
    <ng-container *ngIf="data.mode === 'paymentStatus'">
      <mat-form-field class="mt-2" appearance="outline" style="width: 100%;">
        <mat-label>Payment Status</mat-label>
        <mat-select formControlName="paymentStatus">
          <mat-option value="PAID">
            PAID
          </mat-option>
          <mat-option value="UNPAID">
            UNPAID
          </mat-option>
          <!-- <mat-option value="PARTIAL PAID">
            PARTIAL PAID
          </mat-option> -->
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="data.mode === 'orderAddress'">
      <mat-form-field appearance="outline" class="mt-2" style="width: 100%;">
        <mat-label>Address</mat-label>
        <input matInput formControlName="address" required>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCEL</button>
    <button (click)="save()" color="primary" mat-button type="submit"
      [disabled]="form.invalid || form.pristine">SUBMIT</button>
  </mat-dialog-actions>
</form>