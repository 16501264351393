<form [formGroup]="form" class="status_dialog">
    <div *ngIf="pageTitle !=='partialPay'" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="headline m-0" fxFlex="auto">Update Status</h2>

    </div>
    <mat-dialog-content>
        <div>
            <mat-radio-group class="radio_group" formControlName="comments">
                <mat-radio-button *ngFor="let orderStatus of orderStatusMessage" class="radio_button"
                    [value]="orderStatus.message">
                    <span>
                        {{orderStatus.message | titlecase}}
                    </span>
                </mat-radio-button>
                <mat-radio-button class="radio_button" value="custom">Custom</mat-radio-button>
            </mat-radio-group>

            <mat-form-field appearance="outline" class="full_width" *ngIf="form.get('comments').value == 'custom'">
                <textarea matInput placeholder="Enter here ..." [formControl]="custom"></textarea>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" *ngIf="this.pageTitle !== 'partialPay'">
        <button mat-button mat-dialog-close type="button">CANCEL</button>
        <button (click)="save()" color="primary" mat-button type="submit" [disabled]="form.invalid">SUBMIT</button>
    </mat-dialog-actions>
</form>