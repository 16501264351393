import { MatTabsModule } from '@angular/material/tabs';
import { ReportDownloadComponent } from './report-download/report-download.component';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignExportExcelComponent } from './campaign-export-excel/campaign-export-excel.component';
import { OrderExportExcelComponent } from './order-export-excel/order-export-excel.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatDialogModule,
		MatDividerModule,
		MatSelectModule,
		MatCheckboxModule,
		MatButtonModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatChipsModule,
		MatIconModule,
		MatTabsModule,
		NgxMatSelectSearchModule,
	],
	declarations: [
		CampaignExportExcelComponent,
		OrderExportExcelComponent,
		ReportDownloadComponent,
	],
})
export class ExportExcelModule {}
