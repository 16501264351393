import { PermissionService } from './../services/permission.service';
import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ImportFormGuard implements CanActivate {
	constructor(
		private permissionService: PermissionService,
		private router: Router
	) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.permissionService.isImportForm()) {
			return true;
		} else {
			this.router.navigate(['']);
			return false;
		}
	}
}
