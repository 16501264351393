import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class OrderService extends BaseService {
	protected readonly EndPoint = environment.API_ENDPOINT + 'orders/';

	constructor(protected readonly http: HttpClient) {
		super(http);
	}

	filter(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
			paymentStatus?: string;
			status?: string;
			endDate?: string;
			shop?: string;
			startDate?: string;
			adminApproval?: any;
		} = {}
	) {
		return this.http.get(
			`${this.EndPoint}filter?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}&paymentStatus=${
				option.paymentStatus || ''
			}&status=${option.status}&startDate=${option.startDate || ''}&endDate=${
				option.endDate || ''
			}&shop=${option.shop || ''}&adminApproval=${option.adminApproval}`
		);
	}

	get(
		option: {
			take?: any;
			page?: any;
			searchTerm?: any;
			paymentStatus?: string;
			shop?: string;
		} = {}
	) {
		return this.http.get(
			`${this.EndPoint}?take=${option.take || ''}&page=${
				option.page || ''
			}&searchTerm=${option.searchTerm || ''}&paymentStatus=${
				option.paymentStatus || ''
			}&shop=${option.shop || ''}`
		);
	}

	getOrderSummary(option?: { paymentStatus?: string }): Observable<any> {
		return this.http.get(
			`${this.EndPoint}totalOrdersCount?paymentStatus=${
				option.paymentStatus ? option.paymentStatus : ''
			}`
		);
	}

	approveOrder(payload) {
		return this.http.post(`${this.EndPoint}approveOrder`, payload);
	}

	orderBulkCancel(payload: any): Observable<any> {
		return this.http.post(
			environment.API_ENDPOINT + 'orderCancel/bulkOrderCancel',
			payload
		);
	}

	exportExcel(payload: any): Observable<any> {
		return this.http.post(
			`${this.EndPoint}exportOrders?startDate=${payload.startDate}&endDate=${payload.endDate}&shop=${payload.shop}&paymentStatus=${payload.paymentStatus}&paymentMethod=${payload.paymentMethod}&orderStatus=${payload.orderStatus}&email=${payload.email}`,
			{}
		);
	}
}
