import { Permission } from './../../@shared/model/permission.model';
import { ChangePassword } from './../../@shared/model/changePassword.model';
import { createAction, props } from '@ngrx/store';
import { CurrentUser } from '../../@shared/model/currentUser.model';

export const loadCurrentUser = createAction(
	'[Current user] Load Current User',
	props<{
		currentUser: CurrentUser;
		jwtToken: string;
		permission: Permission[];
	}>()
);

export const loadCurrentUserSuccess = createAction(
	'[Current user] Load Current User Success',
	props<{
		currentUser: CurrentUser;
		jwtToken: string;
		permission: Permission[];
	}>()
);

export const logout = createAction('[Current User] Logout');

export const changePassword = createAction(
	'[Current User] Change Password',
	props<{ payload: ChangePassword }>()
);

export const changePasswordSuccess = createAction(
	'[Current User] Change Password Success'
);

export const changePasswordFail = createAction(
	'[Current User] Change Password Fail'
);

export function clearState(reducer) {
	return function (state, action) {
		if (action.type === logout.type) {
			state = undefined;
		}
		return reducer(state, action);
	};
}
