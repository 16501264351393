import { httpInterceptorProvider } from './httpInterceptorProvider';
import { currentUserReducer } from './../@state/current-user/current-user.reducer';
import { CURRENT_USER_STATE_NAME } from './../@state/current-user/current-user.selector';
import { CurrentUserEffects } from './../@state/current-user/current-user.effect';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { ExportExcelModule } from './../@module/export-excel/export-excel.module';
import { CustomSerializer } from './../@state/router/custom-serializer';
import { appReducer } from './../@state/app.state';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { environment } from '../../environments/environment';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { clearState } from '../@state/current-user/current-user.action';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Gestures
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

/** Import Alyle UI */
import {
	LyTheme2,
	StyleRenderer,
	LY_THEME,
	LY_THEME_NAME,
	LyHammerGestureConfig,
} from '@alyle/ui';

/** Import themes */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		StoreModule.forRoot(appReducer, {
			metaReducers: [storageSyncMetaReducer, clearState],
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: true,
				strictActionSerializability: false,
				strictActionWithinNgZone: true,
				strictActionTypeUniqueness: true,
			},
		}),
		EffectsModule.forRoot([CurrentUserEffects]),
		StoreModule.forFeature(CURRENT_USER_STATE_NAME, currentUserReducer),
		// EffectsModule.forFeature([CurrentUserEffects]),
		StoreRouterConnectingModule.forRoot({
			serializer: CustomSerializer,
		}),
		environment.production
			? []
			: StoreDevtoolsModule.instrument({ logOnly: environment.production }),
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		SharedModule,
		ExportExcelModule,
		AppRoutingModule,
		HttpClientModule,
		NgbModule,
		MatMenuModule,
		MatDialogModule,
		MatSidenavModule,
		MatSnackBarModule,
		ToastrModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		// for HttpClient use:
		LoadingBarHttpClientModule,
		// for Router use:
		LoadingBarRouterModule,
		// for Core use:
		LoadingBarModule,

		HammerModule,
	],
	providers: [
		httpInterceptorProvider,
		[LyTheme2],
		[StyleRenderer],
		// Theme that will be applied to this module
		{ provide: LY_THEME_NAME, useValue: 'minima-light' },
		{ provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
		{ provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
		// Gestures
		{ provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
