import { PermissionService } from './../services/permission.service';
import { loadCurrentUser } from '../../@state/current-user/current-user.action';
import { AuthService } from '../services/auth.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CurrentUserAndToken } from '../model/currentUserAndToken.model';
import { untilDestroyed } from '@ngneat/until-destroy';
import { getCurrentUserAndTokenSelector } from '../../@state/current-user/current-user.selector';
import { AppState } from '../../@state/app.state';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpEvent,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@UntilDestroy()
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	constructor(
		private readonly store: Store<AppState>,
		private readonly authService: AuthService,
		private readonly permissionService: PermissionService
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return this.isTokenExpire(req, next);
	}

	private isTokenExpire(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		this.store
			.select(getCurrentUserAndTokenSelector)
			.pipe(untilDestroyed(this), skip(1), take(1))
			.subscribe((data: CurrentUserAndToken) => {
				if (data && data.jwtToken) {
					const token = data.jwtToken;
					const isExpire = new JwtHelperService().isTokenExpired(token);

					if (isExpire) {
						return this.genereateNewToken(req, next, token);
					} else {
						return next.handle(req);
					}
				}
			});
		return next.handle(req);
	}

	private genereateNewToken(req: HttpRequest<any>, next, token: string) {
		let res: Observable<HttpEvent<any>>;
		this.authService
			.generateNewJwt({ token })
			.pipe(untilDestroyed(this), take(1))
			.subscribe((data: any) => {
				this.setTokenInState(data.token);
				res = next.handle(req);
			});
		return res;
	}

	private setTokenInState(token: string) {
		const payload = new JwtHelperService().decodeToken(token);
		this.permissionService
			.getAllPermission(payload.id)
			.subscribe((res: any) => {
				this.store.dispatch(
					loadCurrentUser({
						currentUser: payload,
						jwtToken: token,
						permission: res.data,
					})
				);
			});
	}
}
