import { Component, HostListener, ViewEncapsulation } from '@angular/core'
import { Menu, NavService } from '../../services/nav.service'
import { NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AppState } from 'src/app/@state/app.state'
import { LayoutService } from '../../services/layout.service'
import { Store } from '@ngrx/store'
import { getCurrentUserAndTokenSelector } from './../../../@state/current-user/current-user.selector'

@UntilDestroy()
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
	public iconSidebar
	public menuItems: Menu[]
	public url: any
	public fileurl: any

	// For Horizontal Menu
	public margin: any = 0
	public width: any = window.innerWidth
	public leftArrowNone: boolean = true
	public rightArrowNone: boolean = false

	constructor(
		private router: Router,
		public navServices: NavService,
		public layout: LayoutService,
		private store: Store<AppState>
	) {
		this.store
			.select(getCurrentUserAndTokenSelector)
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.navServices.items.subscribe((menuItems: any) => {
					const filterMenuItems: any[] = []
					for (let m = 0; m < menuItems.length; m++) {
						if (menuItems[m].title == 'Dashboard') {
							filterMenuItems.push(menuItems[m])
						}
						if (menuItems[m].title == 'Badges') {
							filterMenuItems.push(menuItems[m])
						}

						if (menuItems[m].title == 'Settings') {
							const children: any[] = []
							for (let c = 0; c < menuItems[m].children.length; c++) {
								for (let p = 0; p < data.permission.length; p++) {
									if (
										menuItems[m].children[c].title == data.permission[p].name &&
										data.permission[p].isPermissionGranted
									) {
										children.push(menuItems[m].children[c])
										break
									}
								}
							}
							menuItems[m].children = children
							filterMenuItems.push(menuItems[m])
						} else {
							if (data.permission && data.permission.length) {
								for (let p = 0; p < data.permission.length; p++) {
									if (
										menuItems[m].title == data.permission[p].name &&
										data.permission[p].isPermissionGranted
									) {
										filterMenuItems.push(menuItems[m])
									}
								}
							}
						}
					}

					this.menuItems = filterMenuItems
					this.router.events.subscribe((event) => {
						if (event instanceof NavigationEnd) {
							menuItems.filter((items) => {
								if (items.path === event.url) {
									this.setNavActive(items)
								}
								if (!items.children) {
									return false
								}
								items.children.filter((subItems) => {
									if (subItems.path === event.url) {
										this.setNavActive(subItems)
									}
									if (!subItems.children) {
										return false
									}
									subItems.children.filter((subSubItems) => {
										if (subSubItems.path === event.url) {
											this.setNavActive(subSubItems)
										}
									})
								})
							})
						}
					})
				})
			})
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.width = event.target.innerWidth - 500
	}

	sidebarToggle() {
		this.navServices.collapseSidebar = !this.navServices.collapseSidebar
	}

	// Active Nave state
	setNavActive(item) {
		this.menuItems.filter((menuItem) => {
			if (menuItem !== item) {
				menuItem.active = false
			}
			if (menuItem.children && menuItem.children.includes(item)) {
				menuItem.active = true
			}
			if (menuItem.children) {
				menuItem.children.filter((submenuItems) => {
					if (submenuItems.children && submenuItems.children.includes(item)) {
						menuItem.active = true
						submenuItems.active = true
					}
				})
			}
		})
	}

	// Click Toggle menu
	toggletNavActive(item) {
		if (!item.active) {
			this.menuItems.forEach((a) => {
				if (this.menuItems.includes(item)) {
					a.active = false
				}
				if (!a.children) {
					return false
				}
				a.children.forEach((b) => {
					if (a.children.includes(item)) {
						b.active = false
					}
				})
			})
		}
		item.active = !item.active
	}

	// For Horizontal Menu
	scrollToLeft() {
		if (this.margin >= -this.width) {
			this.margin = 0
			this.leftArrowNone = true
			this.rightArrowNone = false
		} else {
			this.margin += this.width
			this.rightArrowNone = false
		}
	}

	scrollToRight() {
		if (this.margin <= -3051) {
			this.margin = -3464
			this.leftArrowNone = false
			this.rightArrowNone = true
		} else {
			this.margin += -this.width
			this.leftArrowNone = false
		}
	}
}
