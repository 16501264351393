<div class="media profile-media">
  <img class="b-r-10 currentUser_img"
    [src]="currentUser?.image ? currentUser?.image: 'assets/images/dashboard/profile.jpg'" alt="">
  <div class="media-body"><span>{{ currentUser?.firstName |titlecase}} {{currentUser?.lastName |titlecase}}</span>
    <p class="mb-0 font-roboto">{{currentUser?.userPermission ? currentUser?.userPermission.name:'None'|titlecase}} <i
        class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a routerLink='/admin/profile'>
      <app-feather-icons [icon]="'user'"></app-feather-icons><span>Profile</span>
    </a></li>
  <!-- <li><a href="#">
      <app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span>
    </a></li>
  <li><a href="#">
      <app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span>
    </a></li> -->
  <li><a>
      <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span>
    </a></li>
  <li><a (click)="userLogOut()">
      <app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span>
    </a></li>
</ul>